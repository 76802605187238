
import { Component } from 'vue-property-decorator';
import PartBase from '@/components/parts/partBase.vue';

@Component
export default class Part3 extends PartBase {
  get imageObj() {
    return this.store.getters.imageObj;
  }
}
