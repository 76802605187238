
import { Component, Vue } from 'vue-property-decorator';
import Part1 from '@/components/parts/part1.vue';
import Part2 from '@/components/parts/part2.vue';
import Part3 from '@/components/parts/part3.vue';
import store from '@/stores/store';
import Loader from '@/components/Loader.vue';
import { Getter } from 'vuex-class';
import Offline from '@/components/offline.vue';

@Component({
  components: { Offline, Loader, Part1, Part2, Part3 },
})
export default class PhotoTool extends Vue {
  @Getter public loading!: boolean;

  public async mounted() {
    store.commit('setLoading', false);
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}
