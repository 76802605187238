
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ModalError extends Vue {
  @Prop({ default: '' }) public title!: string;
  @Prop({ default: {} }) public error!: object;

  public mounted() {
    (this.$refs.okeBtn as HTMLHeadElement).focus();
  }
}
