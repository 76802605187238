
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Loader extends Vue {
  @Prop({ default: true })
  private loading!: boolean;
  @Prop({ default: false })
  private fullscreen!: boolean;
  @Prop({ default: false })
  private inline!: boolean;

  public get radius(): number {
    return this.inline ? 10 : 20;
  }
}
