import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import { IImage } from '@/shared/models/dataset';
import { nanoid } from 'nanoid';

export default class Firebase {
  public storage: firebase.storage.Storage;
  private db: firebase.firestore.Firestore;
  private readonly auth: firebase.auth.Auth;
  private images: firebase.firestore.CollectionReference;
  private users: firebase.firestore.CollectionReference;

  constructor() {
    const config = {
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }

    this.storage = firebase.storage();
    this.db = firebase.firestore();
    this.auth = firebase.auth();
    this.images = this.db.collection('images');
    this.users = this.db.collection('users');
  }

  public getAuth(): firebase.auth.Auth {
    return this.auth;
  }

  public async addImage(userId: string, ref: string): Promise<IImage> {
    const nanoId = nanoid(8);
    const newObj: IImage = {
      nanoId,
      userId,
      ref,
      createdAt: new Date(),
    };
    await this.images.doc(nanoId).set(newObj);
    return newObj;
  }
}
