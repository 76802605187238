
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Offline extends Vue {
  public isOnline = navigator.onLine;

  public created() {
    window.addEventListener('online', () => this.handleNetworkChange());
    window.addEventListener('offline', () => this.handleNetworkChange());
  }

  public beforeDestroy() {
    window.removeEventListener('online', () => this.handleNetworkChange());
    window.removeEventListener('offline', () => this.handleNetworkChange());
  }

  public handleNetworkChange() {
    this.isOnline = navigator.onLine;
  }
}
