
import { Component } from 'vue-property-decorator';
import PartBase from '@/components/parts/partBase.vue';

@Component
export default class Part1 extends PartBase {
  public clickFileInput() {
    (this.$parent?.$refs.fileInput as HTMLInputElement)?.click();
  }
}
