
import { Component, Vue } from 'vue-property-decorator';
import store from '@/stores/store';

@Component
export default class PartBase extends Vue {
  public store = store;

  public get hasFile() {
    const el = this.$parent?.$refs.fileInput as HTMLInputElement;
    if (!el || !el.files || el.files.length === 0) {
      return false;
    }

    return el.files.length > 0;
  }

  public reset() {
    store.commit('setDirection', 'prev');

    this.$nextTick(() => {
      store.commit('setImageObj', null);
      store.commit('setPreviewUrl', null);
      (this.$parent?.$refs.fileInput as HTMLInputElement).value = '';
    });

    setTimeout(() => {
      store.commit('setDirection', 'next');
    }, 500);
  }
}
