
// @ts-ignore
import { Component } from 'vue-property-decorator';
import { firebaseService } from '@/services';
import PartBase from '@/components/parts/partBase.vue';
import Loader from '@/components/Loader.vue';
import ModalError from '@/components/modal/modal-error.vue';

@Component({
  components: { ModalError, Loader },
})
export default class Part2 extends PartBase {
  public loading: boolean = false;
  public error: object | null = null;
  public orientation: number | null = null;

  public created() {
    const el = this.$parent?.$refs.fileInput as HTMLInputElement;
    el?.addEventListener('change', () => this.setPreviewUrl());
  }

  public beforeDestroy() {
    const el = this.$parent?.$refs.fileInput as HTMLInputElement;
    el?.removeEventListener('change', () => this.setPreviewUrl());
  }

  public getFile() {
    const el = this.$parent?.$refs.fileInput as HTMLInputElement;
    if (!el || !el.files || el.files.length === 0) {
      return null;
    }

    return el.files[0];
  }

  public async setPreviewUrl() {
    const file = this.getFile();
    if (!file) {
      return null;
    }

    // check size (5 MB, 5242880)
    if (file.size > 5242880) {
      this.error = {
        title: 'De afbeelding is te groot',
        message: 'Deze mag maximaal 5 MB groot zijn.',
      };
    } else {
      this.error = null;
    }

    this.store.commit('setPreviewUrl', (await this.convert(file)) + '');
  }

  public async upload() {
    const file = this.getFile();
    if (!file || !firebaseService || file.size > 5242880) {
      return;
    }

    try {
      this.loading = true;
      this.error = null;

      const userId = this.store.getters.userId;
      const storagePathAndFilename = `photoapp/${userId}/${file.name}`;
      const originalRef = firebaseService.storage.ref(storagePathAndFilename);
      await originalRef.put(file);

      const imageObj = await firebaseService.addImage(
        this.store.getters.userId,
        storagePathAndFilename
      );

      const url = await originalRef.getDownloadURL();
      imageObj.url = url;
      this.store.commit('setImageObj', imageObj);
    } catch (err) {
      this.error = {
        title: 'Fout bij het uploaden',
        message: 'Ga terug en probeer het nog een keer.',
      };
      // tslint:disable-next-line
      console.error(err);
    }
    this.loading = false;
  }

  private convert(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const fileReader = new FileReader();
      if (fileReader && file) {
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result + '');
        };

        fileReader.onerror = error => reject(error);
      } else {
        reject('No file provided');
      }
    });
  }
}
