import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { firebaseService } from '@/services';
import {IImage} from '@/shared/models/dataset';

Vue.use(Vuex);

interface IRootState {
  userId: string | null;
  loading: boolean;
  direction: string;
  imageObj: IImage | null;
  previewUrl: string | null;
}

const store: StoreOptions<IRootState> = {
  state: {
    userId: null,
    loading: true,
    direction: 'next',
    imageObj: null,
    previewUrl : null,
  },
  getters: {
    userId(state) {
      return state.userId;
    },
    loading(state) {
      return state.loading;
    },
    direction(state) {
      return state.direction;
    },
    imageObj(state) {
      return state.imageObj;
    },
    previewUrl(state) {
      return state.previewUrl;
    },
  },
  mutations: {
    setUserId(state, userId: string) {
      state.userId = userId;
    },
    setLoading(state, loading: boolean) {
      state.loading = loading;
    },
    setDirection(state, direction: string) {
      state.direction = direction;
    },
    setImageObj(state, imageObj: IImage) {
      state.imageObj = imageObj;
    },
    setPreviewUrl(state, previewUrl: string) {
      state.previewUrl = previewUrl;
    },
  },
  actions: {
    async logIn({ commit }): Promise<void> {
      try {
        const userObj = await firebaseService.getAuth().signInAnonymously();
        if (!userObj || !userObj.user) {
          throw new Error('User object not found');
        }
        commit('setUserId', userObj.user.uid);
      } catch (error) {
        // tslint:disable-next-line
        console.error(error);
      }
    },

  },
};

export default new Vuex.Store(store);
